/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import 'babel-polyfill';
import 'typeface-encode-sans-semi-condensed';
//for dev purpose only
import 'event-source-polyfill';

export const onRouteUpdate = () => {
    const target = document.getElementsByTagName('section')[0];
    if (target) {
        target.scrollIntoView(true);
    }
};
